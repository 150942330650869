<template>
  <div id="app" class="container">
    <div v-if="!isPrint">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <p>Upload a csv and and map the columns:</p>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <input
            type="file"
            id="csv_file"
            class="form-control"
            @change="loadCSV($event)"
          />
        </div>
      </div>

      <div class="columns" v-if="parse_csv.length">
        <div class="column is-half is-offset-one-quarter">
          <label for="sortKey">Select Columns : </label>
          <select id="sortKey" v-model="sortKey">
            <option value="" selected disabled>Select</option>
            <option
              v-for="(header, i) in parse_header"
              :key="i"
              :value="header"
            >
              {{ header }}
            </option>
          </select>
        </div>
      </div>

      <div class="columns" v-if="parse_csv.length">
        <div class="column is-half is-offset-one-quarter">
          <button class="button is-primary mr-3" @click="showQR">
            QR Code
          </button>
          <button class="button is-info mr-3" @click="showBar">Bar Code</button>
          <button class="button is-warning" @click="print">Print</button>
        </div>
      </div>
    </div>

    <div class="columns" v-if="isConverting">
      <div class="column">
        <div id="showResult">
          <table class="table is-bordered is-fullwidth mt-3">
            <thead>
              <th>QR / Bar Code</th>
              <th v-for="(header, i) in parse_header" :key="i">
                <strong>{{ header }}</strong>
              </th>
            </thead>

            <tbody>
              <tr v-for="(row, i) in parse_csv" :key="i">
                <td v-if="!isBar">
                  <vue-qrcode :value="row[sortKey]"></vue-qrcode>
                </td>

                <td v-else>
                  <barcode :value="row[sortKey]"></barcode>
                </td>

                <td v-for="(header, h) in parse_header" :key="h">
                  {{ row[header] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
import VueBarcode from "vue-barcode";

export default {
  name: "App",
  components: {
    VueQrcode,
    barcode: VueBarcode,
  },
  data() {
    return {
      parse_header: [],
      parse_csv: [],
      sortKey: "",
      isConverting: false,
      isBar: false,
      isPrint: false,
    };
  },
  methods: {
    showQR() {
      this.isConverting = true;
      this.isBar = false;
    },
    showBar() {
      this.isConverting = true;
      this.isBar = true;
    },
    print() {
      if (!this.isPrint) {
        this.isPrint = true;

        setTimeout(() => {
          window.print();
          this.isPrint = false;
        }, 500);
      }
    },
    csvJSON(csv) {
      var self = this;
      var lines = csv.split("\n");
      var result = [];
      var headers = lines[0].split(",");
      self.parse_header = headers;

      lines.map(function (line, indexLine) {
        if (indexLine < 1) return;

        var obj = {};
        var currentline = line.split(",");

        headers.map(function (header, indexHeader) {
          obj[header] = currentline[indexHeader];
        });

        if (obj[headers[0]].length > 0) result.push(obj);
      });

      return result;
    },
    loadCSV(e) {
      var self = this;

      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsText(e.target.files[0]);

        reader.onload = function (event) {
          var csv = event.target.result;
          self.parse_csv = self.csvJSON(csv);
        };
        reader.onerror = function (evt) {
          if (evt.target.error.name == "NotReadableError") {
            alert("Cannot read file !");
          }
        };
      } else {
        alert("FileReader are not supported in this browser.");
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

.btn {
  background-color: #00d1b2;
  padding-bottom: calc(0.5em - 1px);
  padding-top: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  color: #f4f4f4;
  border: transparent;
  border-radius: 4px;
  font-size: 1rem;
  height: 2.5rem;
  line-height: 1.5;
  margin-top: 8px;
}

.btn:hover {
  background-color: #00c4a7;
  cursor: pointer;
}

#showResult {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table {
  text-align: center;
}
</style>
